@import url("https://fonts.googleapis.com/css2?family=Afacad:ital,wght@0,400..700;1,400..700&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Afacad", sans-serif;
  color: #4d4d4d;
  font-size: 1rem;
}

a {
  text-decoration: none;
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #145C9E;
}

.container {
  width: 90vw;
  margin-inline: auto;
}

button,
.button {
  padding: 0.8rem 1rem;
  border: 1px solid inherit;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.primary-button {
  background-color: #145C9E;
  color: white;
}

.doc-title {
  color: #145C9E;
}

.cards {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}
.cards .card {
  padding: 1rem;
  background-color: white;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.cards .card .card-title {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.cards .card .card-title .icon {
  width: 2rem;
  height: 2rem;
  background-color: #145C9E;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.2rem;
}

.features-title {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.features-title .icon {
  width: 3rem;
  height: 3rem;
  background-color: #145C9E;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.2rem;
}

.features-cards .features {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}
.features-cards .features .feature {
  flex: 1 1 100px;
  background-color: rgba(20, 92, 158, 0.1);
  color: #145C9E;
  padding: 0.2rem 0.5rem;
  border-radius: 0.2rem;
  cursor: pointer;
  text-wrap: nowrap;
}

.page-content {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 2rem;
}

.page-link {
  background-color: rgba(20, 91, 158, 0.1607843137);
  color: #145C9E;
  padding: 0.2rem;
  border-radius: 0.5rem;
  text-wrap: nowrap;
}/*# sourceMappingURL=main.css.map */