.dot {
    height: 0.5rem;
    width: 0.5rem;
    background-color: rgb(171, 171, 171);
    border-radius: 50%;
}

.element {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem;
    border-left: 1px solid transparent;
}

.active {
    background-color: #F2F9FF;
    color: #145C9E;
    border-left: 1px solid #145C9E;

    .dot {
        background-color: #145C9E;
    }
}

.feature-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    cursor: pointer;
    padding: 1rem;
}

.feature {
    max-height: 3.5rem;
    overflow: hidden;
}

.feature-open {
    animation: animateHeight 0.5s linear forwards;
}

.rotate {
    animation: rotateIcon 0.2s forwards linear;
}


.feature-close {
    max-height: 3.5rem;
    animation: animateReverseHeight 0.5s linear forwards;
}

.un-rotate {
    animation: unRotateIcon 0.3s forwards linear;
}

.side-bar {
    width: 260px;
    display: grid;
    height: fit-content;
}

.page-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100vw;
    min-height: 100vh;

    header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        background-color: #F2F9FF;
        padding: 1rem;
        border-radius: 0.5rem;
        margin-top: 1rem;
    }

    footer {
        background-color: #F2F9FF;
        width: 100vw;
        height: 3rem;
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
    }

    .content-container {
        display: flex;
        gap: 1rem;

        .content {
            padding: 1rem;
            background-color: #F2F9FF;
            border-radius: 0.5rem;
            flex: 1;
        }

    }

    header {
        align-items: center;
    }

}

.mobile-menu {
    display: none;
}

@media screen and (max-width: 960px) {
    .mobile-menu {
        display: block;
    }

    .side-bar {
        position: absolute;
        transform: translateX(-120%);
        background-color: white;
        top: 7rem;
        left: 2rem;
        right: 2rem;
        bottom: 0;
        z-index: 1000;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: start;
        justify-content: start;
        gap: 1rem;
        flex-direction: column;

    }

    .open {
        transform: translateX(0);
    }

    .branding {
        span {
            display: none;
        }

        p {
            display: none;
        }
    }

    .support-link {
        span {
            display: none;
        }
    }
}




@keyframes animateHeight {
    from {
        max-height: 3.5rem;
    }

    to {
        max-height: 500px;
    }
}

@keyframes animateReverseHeight {
    from {
        max-height: 500px;
    }

    to {
        max-height: 3.5rem;
    }
}

@keyframes rotateIcon {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(90deg);
    }
}

@keyframes unRotateIcon {
    from {
        transform: rotate(90deg);
    }

    // to {
    //     transform: rotate(0deg);
    // }
}